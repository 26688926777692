import { useState, useEffect } from "react";
import { message } from "antd";
import * as yup from "yup";
import _ from "lodash";
import { useApi } from "../api";
import useApp from "./useApp";
import { uploadTypes } from "../config";
import axios from "axios";

let listSchema = yup.object().shape({
  brands: yup.mixed().when("type", {
    is: "suppression",
    then: yup.array().min(0),
    otherwise: yup.array().min(1, "Target lists must have at least one brand"),
  }),
  type: yup
    .mixed()
    .oneOf(uploadTypes.map((type) => type.value))
    .required("Please select an upload type"),
  segment_name: yup
    .string()
    .trim()
    .max(64, "Must not exceed 64 chars")
    .matches(
      /^[a-zA-Z0-9!-.*'()&$@=;:+ ,?]+$/,
      "Only letters, numbers or dashes allowed for this field "
    )
    .required("Please enter a valid segment name"),
});

function useTargetList() {
  const { getRequest } = useApi();
  const app = useApp();
  const [list, setList] = useState([]);
  const [listReady, setListReady] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState({});
  const [newList, setNewList] = useState({
    file: null,
    brands: [],
    segment_name: "",
    type: "email_only",
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!list.length) {
      getList({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getList = ({ query = {} }) => {
    setIsLoading(true);
    getRequest({
      endpoint: "targetlist/get",
      params: {
        ...query,
      },
    })
      .then(({ results, total }) => {
        setPagination({
          ...pagination,
          total,
          pageSize: query.searchQuery ? total : 10,
          current: query?.page ? query.page : 1,
        });
        setList(results);
      })
      .catch((err) => {
        message.error("Failed to fetch target list", 0);
      })
      .finally(() => {
        setListReady(true);
        setLoadingStatus({});
        setIsLoading(false);
      });
  };

  const updateNewList = (path, value) => {
    const tmp = _.clone(newList);
    _.set(tmp, path, value);
    setNewList(tmp);
  };

  const onTypeChange = (value) => {
    const tmp = _.clone(newList);
    _.set(tmp, "type", value);
    _.set(tmp, "brands", []);
    setNewList(tmp);
  };

  const addNewList = (setVisible, setIsLoading, form) => {
    setIsLoading(true);
    try {
      listSchema.validateSync(newList);
    } catch (e) {
      message.error(e.message);
      return;
    }

    console.log("Uploading ...", {
      ...newList,
      ...(newList.type === "scrub" && {
        brands: app.config?.ALL_BRANDS.filter((brand) =>
          newList.brands.includes(brand.brandid)
        ),
      }),
      segment_name: newList.segment_name,
    });

    getRequest({
      method: "PUT",
      endpoint: "targetlist/upload",
      params: {
        ...newList,
        segment_name: newList.segment_name,
        ...(newList.type === "scrub" && {
          brands: app.config?.ALL_BRANDS.filter((brand) =>
            newList.brands.includes(brand.brandid)
          ),
        }),
      },
    })
      .then((res) => {
        message.success("File uploaded successfully");
        setNewList({
          file: null,
          brands: [],
          segment_name: "",
          type: "email_only",
        });
        getList({});
        form.resetFields();
        setVisible(false);
      })
      .catch((err) => {
        message.error(`Upload failed: ${err.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addNewSuppressionList = async ({
    setVisible,
    setIsLoading,
    form,
    setProgress,
  }) => {
    setIsLoading(true);
    try {
      listSchema.validateSync(newList);
    } catch (e) {
      message.error(e.message);
      return;
    }

    console.log("Uploading ...", {
      ...newList,
      list_name: newList.segment_name,
    });

    const config = {
      headers: { "content-type": "text/csv" },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
      },
    };

    const presignedUrl = await getRequest({
      method: "POST",
      endpoint: "s3/presigned",
      params: {
        type: newList.type,
        list_name: newList.segment_name,
        records: newList.records,
      },
    });

    try {
      const res = await axios.put(presignedUrl.url, newList.file, config);
      message.success("File uploaded successfully");
      setNewList({
        file: null,
        brands: [],
        segment_name: "",
        type: "email_only",
      });
      getList({});
      form.resetFields();
      setVisible(false);
      setIsLoading(false);
      console.log("server res: ", res);
    } catch (err) {
      console.log({ err });
      setIsLoading(false);
    }
  };

  return {
    data: list,
    newList,
    listReady,
    loadingStatus,
    listSchema,
    uploadTypes,
    pagination,
    isLoading,
    actions: {
      getList,
      setLoadingStatus,
      setNewList,
      updateNewList,
      addNewList,
      addNewSuppressionList,
      onTypeChange,
    },
  };
}

export default useTargetList;
