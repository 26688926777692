import type { ColumnsType } from "antd/lib/table";
import { format, compareAsc } from "date-fns";
import { uploadTypes } from "../../../config";
interface DataType {
  pk: string;
  name: string;
  type: string;
  brands: object;
  created: number;
  records: number;
}

export const columns = (app: any): ColumnsType<DataType> => [
  {
    title: "Segment Name",
    dataIndex: "name",
    align: "left",
  },
  {
    title: "Type",
    dataIndex: "type",
    align: "left",
    render: (type) =>
      uploadTypes.find((item) => item.value === type)?.label || "Emails Only",
  },
  {
    title: "Brands",
    dataIndex: "brands",
    align: "left",
    render: (brands: Array<number>, record: any) =>
      Array.isArray(brands)
        ? record?.type === "scrub"
          ? app.config?.ALL_BRANDS.filter((brand: any) =>
              brands.includes(Number(brand.brandid))
            )
              .map((brand: any) => brand.branddescription)
              .join(", ")
          : app.config?.BRANDS.filter((brand: any) =>
              brands.includes(Number(brand.id))
            )
              .map((brand: any) => brand.name)
              .join(", ")
        : "N/A",
  },
  {
    title: "Records",
    dataIndex: "records",
    align: "right",
    render: (records: number) => records.toLocaleString(),
  },
  {
    title: "Uploaded",
    dataIndex: "created",
    align: "left",
    defaultSortOrder: "descend",
    sorter: (a, b) => compareAsc(new Date(a.created), new Date(b.created)),
    render: (created) =>
      created ? format(new Date(created), "dd MMM yyyy H:mm:ss") : "NaN",
  },
];
