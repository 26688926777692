import { useEffect, useState } from "react";
import { message } from "antd";
import { useApi } from "../api";
import cache from "../helpers/cache";

function useApp() {
  const { getRequest } = useApi();

  const [config, setConfig] = useState({});
  const [loadingStatus, setLoadingStatus] = useState({});
  const [appReady, setAppReady] = useState(false);

  useEffect(() => {
    if (cache.get("@ring:config")) {
      setConfig(JSON.parse(cache.get("@ring:config")));
      setLoadingStatus({});
      setAppReady(true);
    } else getConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getConfig = () => {
    getRequest({
      endpoint: "configure",
    })
      .then((results) => {
        cache.set("@ring:config", results?.configuration);
        setConfig(results?.configuration);
      })
      .catch((err) => {
        message.error("Failed to fetch configuration", 0);
      })
      .finally(() => {
        setLoadingStatus({});
        setAppReady(true);
      });
  };

  return {
    appReady,
    config,
    loadingStatus,
  };
}

export default useApp;
