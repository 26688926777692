import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { compareAsc } from "date-fns";
import LoadingPage from "./components/Loading";
import { Login, Redirect } from "./pages/auth";
import HomeLayout from "./components/HomeLayout";
import AuthLayout from "./components/AuthLayout";
import PageNotFound from "./components/PageNotFound";
import sidebar from "./config/sidebar";
import useAuth from "./hooks/useAuth";

function Router() {
  const { user, userLoaded, ...auth } = useAuth();

  useEffect(() => {
    if (
      user.accessToken &&
      compareAsc(new Date(), new Date(user?.expiresInDate)) !== 1
    ) {
      auth.actions.getUserInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoaded]);

  if (!userLoaded) return <LoadingPage />;

  return (
    <Routes>
      {/* Auth Routes */}
      <Route element={<AuthLayout user={user} />}>
        <Route path="login" element={<Login />} />
        <Route path="redirect" element={<Redirect />} />
      </Route>

      {/* Protected Routes */}
      <Route element={<HomeLayout user={user} logOut={auth.actions.logOut} />}>
        {sidebar
          .filter(function filterRecursive(item) {
            if (!user.userGroups) return true;
            return item.children
              ? (item.children = item.children.filter(filterRecursive)).length
              : !item.allow ||
                  user?.userGroups?.some((group) => item.allow.includes(group));
          })
          .map(function mapRecursive({ name, path, component, children }) {
            return children ? (
              children.map(mapRecursive)
            ) : (
              <Route key={name} path={path} element={component} />
            );
          })}
      </Route>

      {/* Page Not Found */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default Router;
