import React, { useState, useMemo, useEffect } from "react";
import Papa from "papaparse";
import {
  Form,
  Input,
  Space,
  Button,
  message,
  Upload,
  DatePicker,
  Table,
  Tabs,
  Spin,
  Popconfirm,
  Divider,
  Alert,
} from "antd";
import type { UploadFile } from "antd/es/upload/interface";
import { PageHeader } from "@ant-design/pro-layout";
import { ReloadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import _ from "lodash";
import UploadCsv from "../../../../components/UploadCsv";
import useLdl from "../../../../hooks/useLdl";
import useApp from "../../../../hooks/useApp";
import useAuth from "../../../../hooks/useAuth";
import { columns, jobsColumns } from "./config";
import CopyToClipBoard from "../../../../components/CopyToClipBoard";

dayjs.extend(utc);

type Props = {};

type AnyObject = {
  brandId: string;
  brandDesc: string;
  campaignID: string;
  campaignDesc: string;
  action: string;
  status: string;
  count: number;
};

const JobDashboard: React.FC<Props> = () => {
  const ldl = useLdl();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingJobs, setIsLoadingJobs] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    ldl.actions.getScheduledJobs(setIsLoadingJobs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!ldl.scheduledJobs?.length)
    return (
      <div className="automations-container">
        <Spin size="large" />
      </div>
    );

  return (
    <div className="automations-container">
      <div className="site-page-header-ghost-wrapper">
        <PageHeader ghost={false} title="LDL" subTitle="Job Dashboard" />
        {successMessage && (
          <Space
            size={"large"}
            direction="horizontal"
            style={{ marginBottom: 20 }}
          >
            <Alert
              message={`The job ${successMessage} has been canceled.`}
              type="info"
            />
            <CopyToClipBoard text={successMessage} />
          </Space>
        )}
      </div>
      <Space direction="vertical" size={"large"}>
        <Space
          direction="horizontal"
          size={"large"}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Space
            direction="horizontal"
            size={"large"}
            split={<Divider type="vertical" />}
          >
            <Button
              className="button-primary"
              type="primary"
              onClick={() => ldl.actions.getScheduledJobs(setIsLoadingJobs)}
              icon={<ReloadOutlined />}
            />
            <Button
              data-testid="bsu-clear-selection"
              onClick={() => {
                ldl.actions.setToCancelJob("");
                setSelectedRowKeys([]);
              }}
              disabled={!selectedRowKeys.length}
            >
              Clear Selection
            </Button>
          </Space>
          <Space style={{ flex: 1 }}>
            <Popconfirm
              title={`Cancel  Job <${ldl.toCancelJob}> ?`}
              placement="topLeft"
              onConfirm={() => {
                setSelectedRowKeys([]);
                ldl.actions.cancelJob({ setIsLoading, setSuccessMessage });
              }}
              onCancel={() => {}}
              okText="Yes"
              okButtonProps={{ danger: true }}
              cancelText="No"
            >
              <Button
                type="primary"
                danger
                disabled={!ldl.toCancelJob}
                loading={isLoading}
              >
                Cancel Job
              </Button>
            </Popconfirm>
          </Space>
        </Space>
        <Table
          columns={jobsColumns()}
          pagination={{ pageSize: 20 }}
          dataSource={ldl.scheduledJobs}
          rowKey={(record: any) => record.jobname}
          scroll={{ x: 2000 }}
          bordered
          size="small"
          loading={isLoadingJobs}
          rowSelection={{
            type: "radio",
            getCheckboxProps: (job: any) => ({
              disabled:
                dayjs(job.scheduleddate).isBefore(
                  dayjs().add(5, "minutes").utc()
                ) || job.jobstatus !== "Scheduled", // Column configuration not to be checked
              name: job.jobname,
            }),
            onChange: (selectedRowKeys: any, selectedRows: any[]) => {
              console.log(
                `selectedRowKeys: ${selectedRowKeys}`,
                "selectedRows: ",
                selectedRows
              );
              ldl.actions.setToCancelJob(`${selectedRowKeys}`);
              setSelectedRowKeys(selectedRowKeys);
            },
            selectedRowKeys: selectedRowKeys,
          }}
        />
      </Space>
    </div>
  );
};

const FileIpload: React.FC<Props> = () => {
  const ldl = useLdl();
  const { user }: any = useAuth();
  const app: any = useApp();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [defaultFileList, setDefaultFileList] = useState<UploadFile<any>[]>([]);
  const [uploadDisabled, setUploadDisabled] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorList, setErrorList] = useState("");

  const disabled = useMemo(
    () =>
      !ldl.bulkSubscriptionUpdateSchema.isValidSync(
        ldl.bulkSubscriptionUpdate
      ) || !ldl.bulkSubscriptionUpdate.file,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ldl.bulkSubscriptionUpdate]
  );

  useEffect(() => {
    if (errorList) message.error(errorList);
  }, [errorList]);

  const validateColumns = (results: any): boolean =>
    results.data.some((line: any, index: number) => {
      const notValid =
        !Number(line[0]) ||
        !Number(line[1]) ||
        !Number(line[2]) ||
        !Number(line[3]) ||
        !["Subscribe", "Unsubscribe"].includes(line[4]);
      // if value is not a number
      if (notValid)
        setErrorList(
          `Line ${index + 1}: File contains error. Please fix and upload again!`
        );
      return notValid;
    });

  const beforeUpload = (file: any) => {
    setUploadDisabled(true);
    setErrorList("");
    if (file.type !== "text/csv") {
      message.error(`${file.name} is not a csv file`);
      setUploadDisabled(false);
      return Upload.LIST_IGNORE;
    }

    Papa.parse(file, {
      skipEmptyLines: true,
      delimiter: ",",
      complete: function (results) {
        console.log({ results });
        // validates file is not empty
        if (!results.data.length) {
          message.error("File must not be empty");
          setDefaultFileList([]);
        }

        // validates file only has 2 columns
        if (results.data.some((line: any) => line.length === 0)) {
          message.error("File should have at least one column");
          setDefaultFileList([]);
        }

        if (validateColumns(results)) {
          setUploadDisabled(false);
          setDefaultFileList([]);
          return;
        }

        const preview: any = results.data.reduce((acc: any, curr: any) => {
          const tmpObject: AnyObject = {
            brandId: curr[2],
            brandDesc:
              app.config?.ALL_BRANDS?.find(
                (brand: any) => Number(brand.brandid) === Number(curr[2])
              )?.branddescription || "N/A",
            campaignID: curr[3],
            campaignDesc:
              app.config?.CAMPAIGNS?.find(
                (campaign: any) => Number(campaign.key) === Number(curr[3])
              )?.value || "N/A",
            action: curr[4],
            status: curr[5],
            count: 0,
          };
          console.log({ tmpObject });

          const key = Object.keys(tmpObject).reduce(
            (acc2: string, curr: string) => {
              acc2 = `${acc2}${tmpObject[curr as keyof typeof tmpObject]}`;
              return acc2;
            },
            ""
          );

          if (!acc[key as keyof typeof acc]) {
            acc[key as keyof typeof acc] = { ...tmpObject, count: 0 };
          }

          acc[key as keyof typeof acc].count += 1;

          return acc;
        }, {});

        ldl.actions.setBulkSubscriptionUpdate({
          ...ldl.bulkSubscriptionUpdate,
          filename: file.name.split(".csv")[0].split(" ").join("_"),
          file: file,
          csv: Papa.unparse(results.data, { newline: "\n" }).replace(/"/g, ""),
          preview: Object.values(preview),
        });

        setUploadDisabled(false);
      },
    });

    return false;
  };

  const onChange = (info: any) => {
    setDefaultFileList(info.fileList);
    if (!info.fileList.length) {
      ldl.actions.setBulkSubscriptionUpdate({
        ...ldl.bulkSubscriptionUpdate,
        filename: "",
        file: null,
        csv: "",
        preview: [],
      });
    }

    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  return (
    <>
      <div className="site-page-header-ghost-wrapper">
        <PageHeader
          ghost={false}
          title="LDL"
          subTitle="Bulk Subscription Update"
        />
        {successMessage && (
          <Space
            size={"large"}
            direction="horizontal"
            style={{ marginBottom: 20 }}
          >
            <Alert
              message={`The job ${successMessage} has been created.`}
              type="info"
            />
            <CopyToClipBoard text={successMessage} />
          </Space>
        )}
      </div>
      <Space direction="vertical" size={"large"}>
        <Form name="basic" layout="vertical" form={form}>
          <Space direction="horizontal" size={"large"}>
            <Form.Item
              label="User Name"
              name="username"
              style={{ width: 250 }}
              initialValue={user?.usernameOG}
            >
              <Input disabled readOnly />
            </Form.Item>

            <Form.Item
              label="Request Date"
              name="requestDate"
              style={{ width: 250 }}
              initialValue={new Date().toISOString()}
            >
              <Input disabled readOnly />
            </Form.Item>
          </Space>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Form.Item label="File To Load">
              <UploadCsv
                beforeUpload={beforeUpload}
                onChange={onChange}
                defaultFileList={defaultFileList}
                setDefaultFileList={setDefaultFileList}
                disabled={uploadDisabled}
                setDisabled={setUploadDisabled}
              />
            </Form.Item>

            <Form.Item
              label="Scheduled Date (UTC)"
              style={{ width: 250 }}
              tooltip="Scheduled date must be 10 minutes in the future"
            >
              <DatePicker
                style={{
                  width: "100%",
                  display: "inline-flex",
                  alignItems: "center",
                }}
                format="MM-DD-YYYY HH:mm"
                showTime={{ format: "HH:mm" }}
                disabledDate={(current) => {
                  return dayjs().add(10, "minutes").utc() >= current;
                }}
                defaultValue={dayjs().add(11, "minutes").utc()}
                onChange={(date, dateString) => {
                  ldl.actions.setBulkSubscriptionUpdate({
                    ...ldl.bulkSubscriptionUpdate,
                    scheduledDate: dateString + ":00",
                  });
                }}
                disabled={isLoading}
              />
            </Form.Item>
          </div>

          {!_.isEmpty(ldl.bulkSubscriptionUpdate.preview) && (
            <Form.Item label="Review Counts" style={{ width: "100%" }}>
              <Table
                columns={columns()}
                pagination={{ pageSize: 20 }}
                dataSource={ldl.bulkSubscriptionUpdate.preview.map(
                  (item: any, i) => ({ key: i, ...item })
                )}
                rowKey={(record: any) => record.key}
              />
            </Form.Item>
          )}

          <Form.Item>
            <Button
              data-testid="bsu-submit"
              className="button-primary"
              type="primary"
              onClick={() =>
                ldl.actions.uploadBulkSubscriptionUpdate({
                  setIsLoading,
                  form,
                  setDefaultFileList,
                  setDisabled: setUploadDisabled,
                  setSuccessMessage,
                })
              }
              disabled={disabled}
              loading={isLoading}
              style={{ width: 250, marginTop: 30 }}
            >
              EXECUTE
            </Button>
          </Form.Item>
        </Form>
      </Space>
    </>
  );
};

const BulkSubscriptionUpdate: React.FC<Props> = () => {
  return (
    <Tabs
      defaultActiveKey="1"
      type="card"
      items={[
        {
          key: "1",
          label: "File Upload",
          children: <FileIpload />,
        },
        {
          key: "2",
          label: "Job Dashboard",
          children: <JobDashboard />,
        },
      ]}
    />
  );
};

export default BulkSubscriptionUpdate;
