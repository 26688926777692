import { useState, useEffect } from "react";
import { message } from "antd";
import _ from "lodash";
import * as yup from "yup";
import { format } from "date-fns";
import { useApi } from "../api";

let automationSchema = yup.object().shape({
  name: yup.string().trim().required("Please add a valid name"),
  conditions: yup
    .array()
    .min(1, "Automation must have at least one condition")
    .of(
      yup.object().shape({
        fact: yup
          .string()
          // .oneOf(["action_type", "project_number"])
          .required("Please select a fact"),
        operator: yup.string().required("Please select an operator"),
        value: yup.lazy((val) =>
          Array.isArray(val)
            ? yup
                .array()
                .of(yup.string())
                .min(
                  1,
                  "Select at least one value for condition of fact <action_type>"
                )
            : yup.string().trim().required("Condition must have a value")
        ),
      })
    ),
  event: yup.object().shape({
    type: yup
      .string()
      .oneOf(["suppression", "segment", "email"])
      .required("Please select a type"),
    params: yup.object().when("type", {
      is: "suppression",
      then: yup.object().shape({
        list_name: yup
          .string()
          .trim()
          .required("Please enter a suppression list"),
      }),
      otherwise: yup.object().when("type", {
        is: "segment",
        then: yup.object().shape({
          brand: yup.string().required("Please select a brand"),
          segment_name: yup
            .string()
            .trim()
            .max(64, "Must not exceed 64 chars")
            .matches(
              /^[a-zA-Z0-9!-.*'()&$@=;:+ ,?]+$/,
              "Only some special characters are allowed"
            )
            .required("Please insert the segment name"),
        }),
        otherwise: yup.object().shape({
          brand: yup.string().required("Please select a brand"),
          campaign_id: yup.number().required("Please insert the campaign id"),
          template_id: yup.number().required("Please insert the template id"),
          // allowDuplicatesIn: yup
          //   .number()
          //   .default(0)
          //   .required("Please insert duplicate config value"),
        }),
      }),
    }),
  }),
});

const newAutomationSkeleton = {
  name: "",
  conditions: [
    {
      fact: null,
      operator: null,
      value: [],
    },
  ],
  matchingRule: "all",
  event: {
    type: null,
    params: {},
  },
  enabled: true,
  expires: 0,
};

function useAutomations() {
  const { getRequest } = useApi();

  const [automations, setAutomations] = useState([]);
  const [automationReady, setAutomationReady] = useState(false);
  const [newAutomation, setNewAutomation] = useState({
    name: "",
    conditions: [
      {
        fact: null,
        operator: null,
        value: [],
      },
    ],
    matchingRule: "all",
    event: {
      type: null,
      params: {},
    },
    enabled: true,
    expires: 0,
  });

  const [editAutomation, setEditAutomation] = useState({});
  const [loadingStatus, setLoadingStatus] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [csvAutomation, setCsvAutomation] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    if (!automations.length) {
      getAutomations({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAutomations = ({ query = {} }) => {
    setIsLoading(true);
    getRequest({
      endpoint: "get",
      params: {
        ...query,
      },
    })
      .then(({ results, total }) => {
        setAutomations(results);
        setPagination({
          ...pagination,
          total,
          pageSize: query.searchQuery ? total : 10,
          current: query?.page ? query.page : 1,
        });
      })
      .catch((err) => {
        message.error("Failed to fetch automations", 0);
      })
      .finally(() => {
        setAutomationReady(true);
        setLoadingStatus({});
        setIsLoading(false);
      });
  };

  const getAutomation = (id) => {
    getRequest({
      endpoint: `get/${id}`,
    })
      .then((response) => {
        setEditAutomation(mapResults(_.get(response, "results[0]")));
      })
      .catch((err) => {
        message.error("Failed to fetch automations", 0);
      });
  };

  const deleteAutomation = (id) => {
    setAutomations(automations.filter((automation) => automation.pk !== id));
    getRequest({
      endpoint: `delete/${id}`,
      method: "DELETE",
    })
      .then(() => {
        message.success(`Successfully deleted automation: ${id} !`);
      })
      .catch((deleteAutomationError) => {
        message.error(
          deleteAutomationError.message || "Could not delete automation"
        );
      });
  };

  const downloadAutomation = (id, record, setShowCsvModal) => {
    const messagekey = "alert_" + id;
    message.loading({
      content: "Fetching triggered records",
      duration: 0,
      key: messagekey,
    });
    getRequest({
      endpoint: `get/triggered/${id}`,
    })
      .then((response) => {
        if (response.Count > 0) {
          setCsvAutomation(formatResultsForCSV(response.results, record));
          setShowCsvModal(true);
        } else {
          message.error("Automation has never been triggered");
          setCsvAutomation({});
        }
        message.destroy(messagekey);
      })
      .catch((err) => {
        console.log(err);
        setCsvAutomation({});
        message.error("Failed to fetch data for CSV");
        message.destroy(messagekey);
      });
  };

  const updateNewAutomation = (path, value) => {
    const tmp = _.set({ ...newAutomation }, path, value);
    setNewAutomation(tmp);
  };

  const updateEditAutomation = (path, value) => {
    const tmp = _.set({ ...editAutomation }, path, value);
    setEditAutomation(tmp);
  };

  const addNewAutomation = (setVisible, setIsLoading, form) => {
    try {
      automationSchema.validateSync(newAutomation);
    } catch (e) {
      message.error(e.message);
      return;
    }

    console.log({ automation: mapAutomation(newAutomation) });
    setIsLoading(true);
    getRequest({
      endpoint: `create`,
      method: "POST",
      params: mapAutomation(newAutomation),
    })
      .then(({ pk }) => {
        setNewAutomation(newAutomationSkeleton);
        form.resetFields();
        setVisible(false);
        getAutomations({});
        message.success(`Successfully created automation: ${pk}!`);
      })
      .catch((deleteAutomationError) => {
        message.error(
          deleteAutomationError.message || "Could not create automation"
        );
      })
      .finally(() => setIsLoading(false));
  };

  const mapResults = (results) => {
    return !results
      ? {}
      : {
          pk: results.pk,
          name: results.name,
          conditions: _.get(results, "automation.conditions.all[0].all"),
          matchingRule: "all",
          event: results.automation.event,
          expires: results.expires,
          enabled: results.enabled,
        };
  };

  const mapAutomation = (obj, isEdit) => ({
    name: obj.name,
    enabled: obj.enabled,
    expires: obj.expires,
    automation: {
      conditions: {
        all: [
          {
            [obj.matchingRule]: obj.conditions,
          },
        ],
      },
      event: {
        ...obj.event,
        params: {
          ...obj.event.params,
          ...(obj.event.type === "email" && !obj.event.params.allowDuplicatesIn
            ? { allowDuplicatesIn: 0 }
            : {}),
        },
      },
    },
  });

  const submitEditAutomation = (setVisible, setIsLoading, form) => {
    try {
      automationSchema.validateSync(editAutomation);
    } catch (e) {
      message.error(e.message);
      return;
    }
    setIsLoading(true);
    getRequest({
      endpoint: `update/${editAutomation.pk}`,
      method: "PUT",
      params: mapAutomation(editAutomation, true),
    })
      .then(({ pk }) => {
        setEditAutomation({});
        form.resetFields();
        setVisible(false);
        getAutomations({});
        message.success(`Successfully edit automation: ${pk}!`);
      })
      .catch((deleteAutomationError) => {
        message.error(
          deleteAutomationError.message || "Could not create automation"
        );
      })
      .finally(() => setIsLoading(false));
  };

  const formatResultsForCSV = (results, record) => {
    let csvobject = {};
    let triggered = [];

    if (results.length) {
      const now = format(Date.now(), "yyyyMMdd_H_mm");
      csvobject.pk = record.pk;
      csvobject.filename = record.name + "_triggered_events_" + now + ".csv";
      // Header row
      triggered.push(["trigger", "emailid", "partyid", "time"]);
      for (let result of results) {
        let createdate = format(
          new Date(result.created),
          "dd MMM yyyy H:mm:ss"
        );
        triggered.push([
          result.sk,
          result.emailId,
          result.memberId,
          createdate,
        ]);
      }
      csvobject.csv = triggered;
    }

    return csvobject;
  };

  const changeStatus = ({
    pk,
    created,
    created_by,
    sk,
    updated,
    ...automation
  }) => {
    setLoadingStatus({ [pk]: true });

    getRequest({
      endpoint: `update/${pk}`,
      method: "PUT",
      params: automation,
    })
      .then(({ pk }) => {
        getAutomations({});
        message.success(`Successfully edit automation: ${pk}!`);
      })
      .catch((deleteAutomationError) => {
        setLoadingStatus({});
        message.error(
          deleteAutomationError.message || "Could not create automation"
        );
      });
  };

  return {
    data: automations,
    automationReady,
    newAutomation,
    editAutomation,
    loadingStatus,
    csvAutomation,
    automationSchema,
    pagination,
    isLoading,
    actions: {
      deleteAutomation,
      setNewAutomation,
      setEditAutomation,
      setCsvAutomation,
      updateNewAutomation,
      addNewAutomation,
      getAutomation,
      getAutomations,
      updateEditAutomation,
      submitEditAutomation,
      changeStatus,
      setLoadingStatus,
      downloadAutomation,
    },
  };
}

export default useAutomations;
