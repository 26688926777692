import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button, Popover } from "antd";
import { CopyOutlined } from "@ant-design/icons";

export default function Copy({ text = "" }) {
  return (
    <CopyToClipboard text={text} onCopy={() => {}}>
      <Popover title="Copied!" trigger="click">
        <Button data-testid="copy-component" icon={<CopyOutlined />} />
      </Popover>
    </CopyToClipboard>
  );
}
