import { useState } from "react";
import { message } from "antd";
import * as yup from "yup";
import { useApi } from "../api";
import useApp from "./useApp";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

let acquireLeasedEmailsSchema = yup.object().shape({
  csv: yup.string().required("Please upload a file"),
  source: yup
    .string()
    .max(64, "Must not exceed 64 chars")
    .required("Pleaser enter a valid source"),
  filename: yup.string().required("Please enter a valid filename"),
});

let loadLeasedEmailsSchema = yup.object().shape({
  csv: yup.string().required("Please upload a file"),
  vendorPartyID: yup.number().required("Pleaser enter a valid vendor part ID"),
  nationalIDType: yup.string().required("Please enter a valid national ID"),
  expireDate: yup
    .date()
    .min(
      dayjs().add(1, "day").format("MM/DD/YYYY"),
      "The expiration date must be in the future"
    )
    .required("Enter a valid expiration date"),
  filename: yup.string().required("Please enter a valid filename"),
});

let globalUnsubscribeSchema = yup.object().shape({
  csv: yup.string().required("Please upload a file"),
  filename: yup.string().required("Please enter a valid filename"),
});

let bulkSubscriptionUpdateSchema = yup.object().shape({
  csv: yup.string().required("Please upload a file"),
  scheduledDate: yup
    .date()
    .min(
      dayjs().add(10, "minutes").utc().format("MM-DD-YYYY HH:mm:ss"),
      "The expiration date must be in the future"
    )
    .required("Enter a valid expiration date"),
  filename: yup.string().required("Please enter a valid filename"),
});

let logViewerSchema = yup.object().shape({
  logGroup: yup.string().required("Please select log group"),
  jobName: yup.string().required("Please enter a job name"),
  logTypes: yup
    .array()
    .of(yup.string())
    .min(1)
    .required("Pleaser select at least one log type"),
});

export default function useLdl() {
  const { getRequest } = useApi();
  const app = useApp();

  const [scheduledJobs, setScheduledJobs] = useState([]);
  const [toCancelJob, setToCancelJob] = useState("");

  const [acquireLeasedEmails, setAcquireLeasedEmails] = useState({
    file: null,
    filename: "",
    source: "",
    csv: "",
  });

  const [loadLeasedEmails, setLoadLeasedEmails] = useState({
    file: null,
    filename: "",
    vendorPartyID: "",
    nationalIDType: "",
    expireDate: dayjs().add(1, "year").format("MM-DD-YYYY"),
    csv: "",
  });

  const [globalUnsubscribe, setGlobalUnsubscribe] = useState({
    file: null,
    filename: "",
    csv: "",
  });

  const [bulkSubscriptionUpdate, setBulkSubscriptionUpdate] = useState({
    file: null,
    filename: "",
    scheduledDate: dayjs().add(11, "minutes").utc().format("MM-DD-YYYY HH:mm"),
    csv: "",
    preview: [],
  });

  const [logViewer, setLogViewer] = useState({
    logGroup: "",
    jobName: "",
    logTypes: ["SUMMARY", "ERROR", "WARNING"],
  });

  const [ldlLogs, setLdlLogs] = useState([]);

  const getScheduledJobs = (setIsLoadingJobs) => {
    setIsLoadingJobs(true);
    getRequest({
      endpoint: "ldl/jobs",
    })
      .then((results) => {
        setScheduledJobs(results?.data);
      })
      .catch((err) => {
        message.error("Failed to fetch scheduled jobs", 0);
      })
      .finally(() => {
        setIsLoadingJobs(false);
      });
  };

  const uploadAcquireLeasedEmails = ({
    setIsLoading,
    form,
    setDefaultFileList,
    setDisabled,
    setSuccessMessage,
  }) => {
    console.log("Uploading...", acquireLeasedEmails);
    setIsLoading(true);
    setDisabled(true);
    getRequest({
      method: "PUT",
      endpoint: "/ldl/acquire-leased-emails",
      params: acquireLeasedEmails,
    })
      .then(({ jobName }) => {
        setSuccessMessage(jobName);
        message.success("File uploaded successfully");
        setAcquireLeasedEmails({
          file: null,
          filename: "",
          source: "",
          csv: "",
        });
        setDefaultFileList([]);
        form.resetFields();
      })
      .catch((err) => {
        console.log({ err });
        message.error(`Upload failed: ${err.message}`);
      })
      .finally(() => {
        setIsLoading(false);
        setDisabled(false);
      });
  };

  const uploadLoadLeasedEmails = ({
    setIsLoading,
    form,
    setDefaultFileList,
    setDisabled,
    setSuccessMessage,
  }) => {
    console.log("Uploading...", {
      ...loadLeasedEmails,
      vendor: app.config?.VENDORS?.find(
        (vendor) => vendor.partyid === loadLeasedEmails.vendorPartyID
      ),
    });

    setIsLoading(true);
    setDisabled(true);
    getRequest({
      method: "PUT",
      endpoint: "/ldl/load-leased-emails",
      params: {
        ...loadLeasedEmails,
        vendor: app.config?.VENDORS?.find(
          (vendor) => vendor.partyid === loadLeasedEmails.vendorPartyID
        ),
      },
    })
      .then(({ jobName }) => {
        setSuccessMessage(jobName);
        message.success("File uploaded successfully");
        setLoadLeasedEmails({
          file: null,
          filename: "",
          vendorPartyID: "",
          nationalIDType: "",
          expireDate: dayjs().add(1, "year").format("MM-DD-YYYY"),
          csv: "",
        });
        setDefaultFileList([]);
        form.resetFields();
      })
      .catch((err) => {
        console.log({ err });
        message.error(`Upload failed: ${err.message}`);
      })
      .finally(() => {
        setIsLoading(false);
        setDisabled(false);
      });
  };

  const uploadGlobalUnsubscribe = ({
    setIsLoading,
    form,
    setDefaultFileList,
    setDisabled,
    setSuccessMessage,
  }) => {
    console.log("Uploading...", globalUnsubscribe);
    setIsLoading(true);
    setDisabled(true);
    getRequest({
      method: "PUT",
      endpoint: "/ldl/global-unsubscribe",
      params: globalUnsubscribe,
    })
      .then(({ jobName }) => {
        setSuccessMessage(jobName);
        message.success("File uploaded successfully");
        setGlobalUnsubscribe({
          file: null,
          filename: "",
          csv: "",
        });
        setDefaultFileList([]);
        form.resetFields();
      })
      .catch((err) => {
        console.log({ err });
        message.error(`Upload failed: ${err.message}`);
      })
      .finally(() => {
        setIsLoading(false);
        setDisabled(false);
      });
  };

  const uploadBulkSubscriptionUpdate = ({
    setIsLoading,
    form,
    setDefaultFileList,
    setDisabled,
    setSuccessMessage,
  }) => {
    const { preview, scheduledDate, ...toUpload } = bulkSubscriptionUpdate;

    console.log("Uploading...", {
      ...toUpload,
      scheduledDate,
    });

    setIsLoading(true);
    setDisabled(true);
    getRequest({
      method: "PUT",
      endpoint: "/ldl/bulk-subscription-update",
      params: {
        ...toUpload,
        scheduled: scheduledDate,
      },
    })
      .then(({ jobName }) => {
        setSuccessMessage(jobName);
        message.success("File uploaded successfully");
        setBulkSubscriptionUpdate({
          file: null,
          filename: "",
          scheduledDate: dayjs()
            .add(11, "minutes")
            .utc()
            .format("MM-DD-YYYY HH:mm"),
          csv: "",
          preview: [],
        });
        setDefaultFileList([]);
        form.resetFields();
      })
      .catch((err) => {
        console.log({ err });
        message.error(`Upload failed: ${err.message}`);
      })
      .finally(() => {
        setIsLoading(false);
        setDisabled(false);
      });
  };

  const cancelJob = ({ setIsLoading, setSuccessMessage }) => {
    console.log("Cancelling Job...", toCancelJob);
    const jobParams = scheduledJobs.find((job) => job.jobname === toCancelJob);
    console.log({ jobParams });

    if (!jobParams) {
      message.error(`Could not find job: ${toCancelJob}`);
      return;
    }

    setIsLoading(true);
    getRequest({
      method: "PUT",
      endpoint: "/ldl/cancel-bulk-subscription-update",
      params: jobParams,
    })
      .then(({ jobName }) => {
        setSuccessMessage(jobName);
        message.success(`Successfully cancelled job: ${toCancelJob}`);
        setToCancelJob("");
      })
      .catch((err) => {
        console.log({ err });
        message.error(`Upload failed: ${err.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getLogs = ({ setIsLoading, form }) => {
    setIsLoading(true);
    getRequest({
      method: "POST",
      endpoint: "ldl/logs",
      params: { ...logViewer, currDT: new Date().toISOString() },
    })
      .then((results) => {
        setLdlLogs(results?.logs);
      })
      .catch((err) => {
        message.error(
          `Failed to fetch ldl logs for job ${logViewer.jobName}`,
          0
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    acquireLeasedEmails,
    loadLeasedEmails,
    acquireLeasedEmailsSchema,
    loadLeasedEmailsSchema,
    globalUnsubscribeSchema,
    bulkSubscriptionUpdateSchema,
    logViewerSchema,
    bulkSubscriptionUpdate,
    globalUnsubscribe,
    scheduledJobs,
    toCancelJob,
    logViewer,
    ldlLogs,
    actions: {
      setAcquireLeasedEmails,
      setLoadLeasedEmails,
      setGlobalUnsubscribe,
      setBulkSubscriptionUpdate,
      setLogViewer,
      uploadAcquireLeasedEmails,
      uploadLoadLeasedEmails,
      uploadGlobalUnsubscribe,
      uploadBulkSubscriptionUpdate,
      getScheduledJobs,
      setToCancelJob,
      cancelJob,
      getLogs,
    },
  };
}
