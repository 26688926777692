const cache = {
  get: (key: string) => (key && localStorage.getItem(key)) || "",
  remove: (key: string | null = null) => {
    if (!key) localStorage.clear();
    else localStorage.removeItem(key);
  },
  set: (key: string, value: string | object) =>
    key &&
    localStorage.setItem(
      key,
      (typeof value === "string" && value) || JSON.stringify(value)
    ),
};

export default cache;
