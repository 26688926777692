import React from "react";
import { Avatar, Menu, Button, Layout } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
const { Header } = Layout;

interface User {
  email?: string;
}

interface PageHeaderProps {
  user: User;
  logOut: () => void;
}

export default function PageHeader({ user, logOut }: PageHeaderProps) {
  return (
    <Header
      style={{
        backgroundColor: "#fff",
      }}
    >
      <Menu
        mode="horizontal"
        selectable={false}
        className="menu"
        style={{
          width: "100%",
          justifyContent: "flex-end",
          // backgroundColor: "lightgrey",
        }}
        items={[
          {
            key: "submenu:1",
            label: (
              <div>
                <Avatar
                  style={{
                    verticalAlign: "middle",
                    backgroundColor: "purple",
                  }}
                  size="large"
                >
                  {user ? user?.email?.charAt(0).toUpperCase() : ""}
                </Avatar>
              </div>
            ),
            children: [
              {
                key: "logout",
                label: (
                  <Button
                    onClick={logOut}
                    type="text"
                    icon={<LogoutOutlined />}
                  >
                    Log Out
                  </Button>
                ),
              },
            ],
          },
        ]}
      ></Menu>
    </Header>
  );
}
