import React, { useState, useMemo } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Button,
  Spin,
  DatePicker,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";

const { Option } = Select;

type Props = {
  visible: boolean;
  automations: any;
  onCancel: any;
  setVisible: Function;
  app: any;
};

const AddAutomation: React.FC<Props> = ({
  visible,
  automations,
  onCancel,
  setVisible,
  app,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const disabled = useMemo(
    () => !automations.automationSchema.isValidSync(automations.newAutomation),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [automations.newAutomation]
  );

  return (
    <Modal
      title="Create an Automation"
      open={visible}
      onOk={() =>
        automations.actions.addNewAutomation(setVisible, setIsLoading, form)
      }
      okText="Submit"
      okButtonProps={{
        loading: isLoading,
        disabled,
        "data-testid": "modal-ok",
      }}
      onCancel={onCancel}
      cancelButtonProps={{
        "data-testid": "modal-cancel",
      }}
      width={700}
      data-testid="modal"
    >
      {_.isEmpty(app.config) ? (
        <div className="automations-container">
          <Spin size="large" />
        </div>
      ) : (
        <Form name="basic" layout="vertical" form={form}>
          <Form.Item label="Automation name" name="name">
            <Input
              placeholder="Type name here"
              onChange={(e) =>
                automations.actions.updateNewAutomation("name", e.target.value)
              }
              value={automations.newAutomation.name}
            />
          </Form.Item>
          {/* RULES */}
          <Form.Item label="Matching Criteria">
            {automations.newAutomation.conditions.map(
              (condition: any, i: number) => (
                <Space key={i} direction="horizontal" size="large">
                  <Form.Item style={{ width: 200 }}>
                    <Select
                      showSearch
                      placeholder="Fact"
                      optionFilterProp="children"
                      onChange={(value) => {
                        automations.actions.updateNewAutomation(
                          `conditions[${i}]`,
                          {
                            fact: value,
                            operator: null,
                            value: value === "action_type" ? [] : null,
                          }
                        );
                      }}
                      filterOption={(input, option) =>
                        (option!.children as unknown as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      value={condition.fact}
                    >
                      {app.config?.FACTS.map((criteria: any, i: number) => (
                        <Option key={i} value={criteria?.fact}>
                          {criteria?.fact}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item style={{ width: 200 }}>
                    <Select
                      showSearch
                      placeholder="Operator"
                      optionFilterProp="children"
                      onChange={(value) =>
                        automations.actions.updateNewAutomation(
                          `conditions[${i}].operator`,
                          value
                        )
                      }
                      filterOption={(input, option) =>
                        (option!.children as unknown as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      value={condition.operator}
                    >
                      {app.config?.OPERATORS[
                        app.config?.FACTS.find(
                          (c: any) =>
                            c.fact ===
                            automations.newAutomation.conditions[i]?.fact
                        )?.value_type
                      ]?.map((operator: any, i: number) => (
                        <Option key={i} value={operator.value}>
                          {operator.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item style={{ width: 200 }}>
                    {app.config?.FACTS.find(
                      (c: any) =>
                        c.fact === automations.newAutomation.conditions[i]?.fact
                    )?.values ? (
                      <Select
                        {...([
                          "tracking_site_id",
                          "promo_code",
                          "alternate_country",
                          "alternate_profession",
                          "alternate_specialty",
                        ].includes(
                          automations.newAutomation.conditions[i]?.fact
                        )
                          ? {
                              mode: "tags",
                            }
                          : {
                              showSearch: true,
                              mode: "multiple",
                            })}
                        placeholder="Value"
                        onChange={(value) => {
                          if (
                            [
                              "promo_code",
                              "alternate_country",
                              "alternate_profession",
                              "alternate_specialty",
                            ].includes(
                              automations.newAutomation.conditions[i]?.fact
                            )
                          ) {
                            const filteredTags = value.filter(
                              (tag: any) => /^\d+$/.test(tag) && !/^0/.test(tag)
                            );

                            if (
                              [
                                "alternate_country",
                                "alternate_profession",
                                "alternate_specialty",
                              ].includes(
                                automations.newAutomation.conditions[i]?.fact
                              ) ||
                              value.length <= 10
                            ) {
                              automations.actions.updateNewAutomation(
                                `conditions[${i}].value`,
                                filteredTags
                              );
                            }

                            return;
                          }
                          automations.actions.updateNewAutomation(
                            `conditions[${i}].value`,
                            value
                          );
                        }}
                        value={condition.value?.length ? condition.value : []}
                        options={app.config?.FACTS.find(
                          (c: any) =>
                            c.fact ===
                            automations.newAutomation.conditions[i]?.fact
                        )?.values?.map((v: any, i: number) => ({
                          label: v.label,
                          value: v.value,
                        }))}
                      />
                    ) : (
                      <Input
                        placeholder="Enter a value here"
                        onChange={(e) =>
                          automations.actions.updateNewAutomation(
                            `conditions[${i}].value`,
                            e.target.value &&
                              app.config?.FACTS.find(
                                (c: any) =>
                                  c.fact ===
                                  automations.newAutomation.conditions[i]?.fact
                              )?.value_type === "number"
                              ? parseInt(e.target.value)
                              : e.target.value
                          )
                        }
                        value={condition.value}
                      />
                    )}
                  </Form.Item>
                </Space>
              )
            )}
          </Form.Item>
          <Form.Item style={{ textAlign: "end" }}>
            <Space direction="horizontal" size="large">
              {automations.newAutomation.conditions.length > 1 && (
                <Button
                  type="primary"
                  danger
                  onClick={() =>
                    automations.actions.setNewAutomation({
                      ...automations.newAutomation,
                      conditions: automations.newAutomation.conditions.slice(
                        0,
                        -1
                      ),
                    })
                  }
                >
                  -
                </Button>
              )}

              <Button
                type="primary"
                onClick={() =>
                  automations.actions.setNewAutomation({
                    ...automations.newAutomation,
                    conditions: [
                      ...automations.newAutomation.conditions,
                      {
                        fact: null,
                        operator: null,
                        value: [],
                      },
                    ],
                  })
                }
              >
                +
              </Button>
            </Space>
          </Form.Item>
          {/* TARGET */}
          <Form.Item label="Target">
            <Space
              direction="vertical"
              size="large"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Form.Item label="Type" style={{ width: 170 }}>
                <Select
                  placeholder="Matching rule"
                  onChange={(value) => {
                    automations.actions.updateNewAutomation(`event`, {
                      type: value,
                      params: {},
                    });
                  }}
                  value={automations.newAutomation.event.type}
                >
                  {app.config?.TARGETS.map((target: any, i: number) => (
                    <Option key={i} value={target.type}>
                      {target.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Space>
            {automations.newAutomation.event?.type === "email" ? (
              <Space size={"large"} direction="horizontal">
                <Form.Item label="Brand" style={{ width: 140 }}>
                  <Select
                    placeholder="Select a brand"
                    onChange={(value) =>
                      automations.actions.updateNewAutomation(
                        `event.params.brand`,
                        value
                      )
                    }
                    value={automations.newAutomation.event?.params?.brand}
                  >
                    {app.config?.BRANDS.map((brand: any, i: number) => (
                      <Option key={i} value={brand.id}>
                        {brand.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Campaign ID" style={{ width: 140 }}>
                  <InputNumber
                    placeholder="Enter Campaign ID"
                    onChange={(value) =>
                      automations.actions.updateNewAutomation(
                        `event.params.campaign_id`,
                        value
                      )
                    }
                    value={automations.newAutomation.event?.params?.campaign_id}
                    controls={false}
                    style={{ width: 140 }}
                  />
                </Form.Item>
                <Form.Item label="Template ID" style={{ width: 140 }}>
                  <InputNumber
                    placeholder="Enter Template ID"
                    onChange={(value) =>
                      automations.actions.updateNewAutomation(
                        `event.params.template_id`,
                        value
                      )
                    }
                    value={automations.newAutomation.event?.params?.template_id}
                    controls={false}
                    style={{ width: 140 }}
                  />
                </Form.Item>
                <Form.Item
                  label="Allow Duplicates In (days)"
                  style={{ width: 180 }}
                >
                  <InputNumber
                    placeholder="0"
                    onChange={(value) =>
                      automations.actions.updateNewAutomation(
                        `event.params.allowDuplicatesIn`,
                        value
                      )
                    }
                    value={
                      automations.newAutomation.event?.params?.allowDuplicatesIn
                    }
                    controls={false}
                    style={{ width: 150 }}
                  />
                </Form.Item>
              </Space>
            ) : automations.newAutomation.event?.type === "segment" ? (
              <Space size={"large"} direction="horizontal">
                <Form.Item label="Brand" style={{ width: 140 }}>
                  <Select
                    placeholder="Select a brand"
                    onChange={(value) =>
                      automations.actions.updateNewAutomation(
                        `event.params.brand`,
                        value
                      )
                    }
                    value={automations.newAutomation.event?.params?.brand}
                  >
                    {app.config?.BRANDS.map((brand: any, i: number) => (
                      <Option key={i} value={brand.id}>
                        {brand.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Segment Name" style={{ width: 140 }}>
                  <Input
                    placeholder="Enter segement name"
                    onChange={(e) =>
                      automations.actions.updateNewAutomation(
                        `event.params.segment_name`,
                        e.target.value
                      )
                    }
                    value={
                      automations.newAutomation.event?.params?.segment_name
                    }
                  />
                </Form.Item>
              </Space>
            ) : (
              <Space size={"large"} direction="horizontal">
                <Form.Item label="List Name" style={{ width: 150 }}>
                  <Input
                    placeholder="Enter list name"
                    onChange={(e) =>
                      automations.actions.updateNewAutomation(
                        `event.params.list_name`,
                        e.target.value
                      )
                    }
                    value={automations.newAutomation.event?.params?.list_name}
                    maxLength={64}
                  />
                </Form.Item>
              </Space>
            )}
          </Form.Item>

          {/* Expiration */}

          <Form.Item label="Expiration" style={{ width: 170 }}>
            <DatePicker
              disabledDate={(current) => {
                return dayjs().startOf("day") >= current;
              }}
              onChange={(date) => {
                automations.actions.updateNewAutomation(
                  "expires",
                  date?.endOf("day").valueOf()
                );
              }}
              data-testid="automation-add-dp"
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default AddAutomation;
