import React, { useState, useMemo } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Space,
  Button,
  Spin,
  InputNumber,
  DatePicker,
} from "antd";
import dayjs from "dayjs";
import _ from "lodash";
const { Option } = Select;

type Props = {
  visible: boolean;
  automations: any;
  onCancel: any;
  setVisible: Function;
  app: any;
};

const AddAutomation: React.FC<Props> = ({
  visible,
  automations,
  onCancel,
  setVisible,
  app,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const disabled = useMemo(
    () => !automations.automationSchema.isValidSync(automations.editAutomation),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [automations.editAutomation]
  );

  return (
    <Modal
      title="Edit Automation"
      open={visible}
      onOk={() =>
        automations.actions.submitEditAutomation(setVisible, setIsLoading, form)
      }
      okText="Update"
      okButtonProps={{ loading: isLoading, disabled }}
      onCancel={onCancel}
      width={700}
    >
      {_.isEmpty(automations.editAutomation) || _.isEmpty(app.config) ? (
        <div className="automations-container">
          <Spin size="large" />
        </div>
      ) : (
        <Form name="basic" layout="vertical" form={form}>
          <Form.Item label="Automation name">
            <Input
              placeholder="Type name here"
              onChange={(e) =>
                automations.actions.updateEditAutomation("name", e.target.value)
              }
              value={automations.editAutomation?.name}
            />
          </Form.Item>

          <Form.Item label="Matching Criteria">
            {automations.editAutomation.conditions.map(
              (condition: any, i: number) => (
                <Space key={i} direction="horizontal" size="large">
                  <Form.Item style={{ width: 170 }}>
                    <Select
                      showSearch
                      placeholder="Fact"
                      optionFilterProp="children"
                      onChange={(value) => {
                        automations.actions.updateEditAutomation(
                          `conditions[${i}]`,
                          {
                            fact: value,
                            operator: null,
                            value: value === "action_type" ? [] : null,
                          }
                        );
                      }}
                      filterOption={(input, option) =>
                        (option!.children as unknown as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      value={condition.fact}
                    >
                      {app.config?.FACTS.map((criteria: any, i: number) => (
                        <Option key={i} value={criteria?.fact}>
                          {criteria?.fact}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item style={{ width: 130 }}>
                    <Select
                      showSearch
                      placeholder="Operator"
                      optionFilterProp="children"
                      onChange={(value) =>
                        automations.actions.updateEditAutomation(
                          `conditions[${i}].operator`,
                          value
                        )
                      }
                      filterOption={(input, option) =>
                        (option!.children as unknown as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      value={condition.operator}
                    >
                      {app.config?.OPERATORS[
                        app.config?.FACTS.find(
                          (c: any) =>
                            c.fact ===
                            automations.editAutomation.conditions[i]?.fact
                        )?.value_type
                      ]?.map((operator: any, i: number) => (
                        <Option key={i} value={operator.value}>
                          {operator.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item style={{ width: 150 }}>
                    {app.config?.FACTS.find(
                      (c: any) =>
                        c.fact ===
                        automations.editAutomation.conditions[i]?.fact
                    )?.values ? (
                      <Select
                        {...([
                          "tracking_site_id",
                          "promo_code",
                          "alternate_country",
                          "alternate_profession",
                          "alternate_specialty",
                        ].includes(
                          automations.editAutomation.conditions[i]?.fact
                        )
                          ? {
                              mode: "tags",
                            }
                          : {
                              showSearch: true,
                              mode: "multiple",
                            })}
                        placeholder="Value"
                        onChange={(value) => {
                          if (
                            [
                              "promo_code",
                              "alternate_country",
                              "alternate_profession",
                              "alternate_specialty",
                            ].includes(
                              automations.editAutomation.conditions[i]?.fact
                            )
                          ) {
                            const filteredTags = value.filter(
                              (tag: any) => /^\d+$/.test(tag) && !/^0/.test(tag)
                            );

                            if (
                              [
                                "alternate_country",
                                "alternate_profession",
                                "alternate_specialty",
                              ].includes(
                                automations.editAutomation.conditions[i]?.fact
                              ) ||
                              value.length <= 10
                            ) {
                              automations.actions.updateEditAutomation(
                                `conditions[${i}].value`,
                                filteredTags
                              );
                            }
                            return;
                          }
                          automations.actions.updateEditAutomation(
                            `conditions[${i}].value`,
                            value
                          );
                        }}
                        value={condition.value?.length ? condition.value : []}
                        options={app.config?.FACTS.find(
                          (c: any) =>
                            c.fact ===
                            automations.editAutomation.conditions[i]?.fact
                        )?.values?.map((v: any, i: number) => ({
                          label: v.label,
                          value: v.value,
                        }))}
                      />
                    ) : (
                      <Input
                        placeholder="Enter a value here"
                        onChange={(e) =>
                          automations.actions.updateEditAutomation(
                            `conditions[${i}].value`,
                            e.target.value &&
                              app.config?.FACTS.find(
                                (c: any) =>
                                  c.fact ===
                                  automations.editAutomation.conditions[i]?.fact
                              )?.value_type === "number"
                              ? parseInt(e.target.value)
                              : e.target.value
                          )
                        }
                        value={condition.value}
                      />
                    )}
                  </Form.Item>
                </Space>
              )
            )}
          </Form.Item>
          <Form.Item style={{ textAlign: "end" }}>
            <Space direction="horizontal" size="large">
              {automations.editAutomation.conditions.length > 1 && (
                <Button
                  type="primary"
                  danger
                  onClick={() =>
                    automations.actions.setEditAutomation({
                      ...automations.editAutomation,
                      conditions: automations.editAutomation.conditions.slice(
                        0,
                        -1
                      ),
                    })
                  }
                >
                  -
                </Button>
              )}

              <Button
                type="primary"
                onClick={() =>
                  automations.actions.setEditAutomation({
                    ...automations.editAutomation,
                    conditions: [
                      ...automations.editAutomation.conditions,
                      {
                        fact: null,
                        operator: null,
                        value: [],
                      },
                    ],
                  })
                }
              >
                +
              </Button>
            </Space>
          </Form.Item>
          {/* TARGET */}
          <Form.Item label="Target">
            <Space
              direction="vertical"
              size="large"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Form.Item label="Type" style={{ width: 170 }}>
                <Select
                  placeholder="Select a matching rule"
                  onChange={(value) =>
                    automations.actions.updateEditAutomation(`event`, {
                      type: value,
                      params: {},
                    })
                  }
                  value={automations.editAutomation.event.type}
                >
                  {app.config?.TARGETS.map((target: any, i: number) => (
                    <Option key={i} value={target.type}>
                      {target.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Space>
            {automations.editAutomation.event?.type === "suppression" ? (
              <Space size={"large"} direction="horizontal">
                <Form.Item label="List Name" style={{ width: 150 }}>
                  <Input
                    placeholder="Enter a value here"
                    onChange={(e) =>
                      automations.actions.updateEditAutomation(
                        `event.params.list_name`,
                        e.target.value
                      )
                    }
                    value={automations.editAutomation.event?.params?.list_name}
                    maxLength={64}
                  />
                </Form.Item>
              </Space>
            ) : automations.editAutomation.event?.type === "segment" ? (
              <Space size={"large"} direction="horizontal">
                <Form.Item label="Brand" style={{ width: 140 }}>
                  <Select
                    placeholder="Select a brand"
                    onChange={(value) =>
                      automations.actions.updateEditAutomation(
                        `event.params.brand`,
                        value
                      )
                    }
                    value={automations.editAutomation.event?.params?.brand}
                  >
                    {app.config?.BRANDS.map((brand: any, i: number) => (
                      <Option key={i} value={brand.id}>
                        {brand.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Segment Name" style={{ width: 140 }}>
                  <Input
                    placeholder="Enter a value here"
                    onChange={(e) =>
                      automations.actions.updateEditAutomation(
                        `event.params.segment_name`,
                        e.target.value
                      )
                    }
                    value={
                      automations.editAutomation.event?.params?.segment_name
                    }
                  />
                </Form.Item>
              </Space>
            ) : (
              <Space size={"large"} direction="horizontal">
                <Form.Item label="Brand" style={{ width: 140 }}>
                  <Select
                    placeholder="Select a brand"
                    onChange={(value) =>
                      automations.actions.updateEditAutomation(
                        `event.params.brand`,
                        value
                      )
                    }
                    value={automations.editAutomation.event?.params?.brand}
                  >
                    {app.config?.BRANDS.map((brand: any, i: number) => (
                      <Option key={i} value={brand.id}>
                        {brand.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Campaign ID" style={{ width: 140 }}>
                  <InputNumber
                    placeholder="Enter Campaign ID"
                    onChange={(value) =>
                      automations.actions.updateEditAutomation(
                        `event.params.campaign_id`,
                        value
                      )
                    }
                    value={
                      automations.editAutomation.event?.params?.campaign_id
                    }
                    controls={false}
                    style={{ width: 140 }}
                  />
                </Form.Item>
                <Form.Item label="Template ID" style={{ width: 140 }}>
                  <InputNumber
                    placeholder="Enter Template ID"
                    onChange={(value) =>
                      automations.actions.updateEditAutomation(
                        `event.params.template_id`,
                        value
                      )
                    }
                    value={
                      automations.editAutomation.event?.params?.template_id
                    }
                    controls={false}
                    style={{ width: 140 }}
                  />
                </Form.Item>
                <Form.Item
                  label="Allow Duplicates In (days)"
                  style={{ width: 180 }}
                >
                  <InputNumber
                    placeholder="0"
                    onChange={(value) =>
                      automations.actions.updateEditAutomation(
                        `event.params.allowDuplicatesIn`,
                        value
                      )
                    }
                    value={
                      automations.editAutomation.event?.params
                        ?.allowDuplicatesIn
                    }
                    controls={false}
                    style={{ width: 150 }}
                  />
                </Form.Item>
              </Space>
            )}
          </Form.Item>

          <Form.Item label="Expiration" style={{ width: 170 }}>
            <DatePicker
              disabledDate={(current) => {
                return dayjs().startOf("day") >= current;
              }}
              onChange={(date, dateString) => {
                automations.actions.updateEditAutomation(
                  "expires",
                  date ? date?.endOf("day").valueOf() : 0
                );
              }}
              value={
                automations.editAutomation?.expires
                  ? dayjs(automations.editAutomation?.expires)
                  : null
              }
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default AddAutomation;
