import React from "react";
import {
  ControlOutlined,
  FileSyncOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import Home from "../pages/main/home/Home";
import TargetList from "../pages/main/target-list";
import {
  AcquireLeadedEmails,
  LoadLeadedEmails,
  GlobalUnsubscribe,
  BulkSubscriptionUpdate,
  LogViewer,
} from "../pages/main/ldl";

interface SidebarItem {
  name: string;
  path?: string;
  label: string;
  icon?: React.ReactNode;
  component?: React.ReactNode;
  allow?: string[];
  children?: SidebarItem[];
}

type Sidebar = SidebarItem[];

const sideBar: Sidebar = [
  {
    name: "/",
    path: "/",
    label: "Automations",
    icon: <ControlOutlined />,
    component: <Home />,
    allow: ["RING:Automations"],
  },
  {
    name: "/target-list",
    path: "/target-list",
    label: "Target List",
    icon: <FileSyncOutlined />,
    component: <TargetList />,
    allow: ["RING:TargetList"],
  },
  {
    name: "ldl",
    label: "LDL",
    icon: <FileExcelOutlined />,
    children: [
      {
        name: "/ldl/acquire-leased-emails",
        path: "/ldl/acquire-leased-emails",
        label: "Acquire Leased Emails",
        component: <AcquireLeadedEmails />,
        allow: ["LDL:Admin", "LDL:ALE"],
      },
      {
        name: "/ldl/load-leased-emails",
        path: "/ldl/load-leased-emails",
        label: "Load Leased Emails",
        component: <LoadLeadedEmails />,
        allow: ["LDL:Admin", "LDL:LLE"],
      },
      {
        name: "/ldl/global-unsubscribe",
        path: "/ldl/global-unsubscribe",
        label: "Global Unsubscribe",
        component: <GlobalUnsubscribe />,
        allow: ["LDL:Admin", "LDL:GU"],
      },
      {
        name: "/ldl/bulk-subscription-update",
        path: "/ldl/bulk-subscription-update",
        label: "Bulk Subscription Update",
        component: <BulkSubscriptionUpdate />,
        allow: ["LDL:Admin", "LDL:BSU"],
      },
      {
        name: "/ldl/log-viewer",
        path: "/ldl/log-viewer",
        label: "Log Viewer",
        component: <LogViewer />,
        allow: ["LDL:Admin", "LDL:ALE", "LDL:LLE", "LDL:GU", "LDL:BSU"],
      },
    ],
  },
];

export default sideBar;
