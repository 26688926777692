import React from "react";
import { Button } from "antd";
import { GoogleOutlined } from "@ant-design/icons";
import { authBaseUrl, redirectUri, clientId } from "../../config";

function Login() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Button
        size="large"
        type="primary"
        data-testid="login-button"
        href={`${authBaseUrl}login?client_id=${clientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+ongage/fullaccess+openid+phone+profile&redirect_uri=${redirectUri}`}
        icon={<GoogleOutlined />}
      >
        Sign In
      </Button>
    </div>
  );
}

export default Login;
