import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import LoadingPage from "../../components/Loading";
import cache from "../../helpers/cache";

function Redirect() {
  const user = useAuth();
  // eslint-disable-next-line
  const [search] = useSearchParams();

  const code = search.get("code");
  cache.remove();
  useEffect(() => {
    user.actions.getAccessToken(code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingPage />;
}

export default Redirect;
