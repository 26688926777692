import type { ColumnsType } from "antd/lib/table";
import compareAsc from "date-fns/compareAsc";

interface DataType {
  appLogData: any;
  appLogKey: any;
  appLogType: string;
  logLevel: string;
  timestampReadable: string;
}

export const jobsColumns = (): ColumnsType<DataType> => [
  {
    title: "Log Level",
    dataIndex: "logLevel",
    align: "left",
    defaultSortOrder: "ascend",
    sorter: (a: any, b: any) => a.logLevel.localeCompare(b.logLevel),
  },
  {
    title: "Timestamp",
    dataIndex: "timestampReadable",
    align: "left",
    sorter: (a: any, b: any) =>
      compareAsc(new Date(a.timestampReadable), new Date(b.timestampReadable)),
  },
  {
    title: "App Log Type",
    dataIndex: "appLogType",
  },
  {
    title: "App Log Key",
    dataIndex: "appLogKey",
    align: "left",
    render: (appLogKey) => JSON.stringify(appLogKey),
    // ellipsis: {
    //   showTitle: false,
    // },
    // render: (appLogKey) => (
    //   <Tooltip placement="leftTop" title={JSON.stringify(appLogKey)}>
    //     {JSON.stringify(appLogKey)}
    //   </Tooltip>
    // ),
  },
  {
    title: "App Log Data",
    dataIndex: "appLogData",
    align: "left",
    render: (appLogData) => JSON.stringify(appLogData),
    // ellipsis: {
    //   showTitle: false,
    // },
    // render: (appLogData) => (
    //   <Tooltip placement="leftTop" title={JSON.stringify(appLogData)}>
    //     {JSON.stringify(appLogData)}
    //   </Tooltip>
    // ),
  },
];
