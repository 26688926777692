import React from "react";
import { useState, useEffect } from "react";
import { Table, Button, Spin, Input, Space } from "antd";
import useAutomations from "../../../hooks/useAutomations";
import useApp from "../../../hooks/useApp";
import AddAutomation from "./Add";
import EditAutomation from "./Edit";
import DownloadCsv from "./DownloadCSV";
import { columns } from "./config";
import {
  PlusOutlined,
  SearchOutlined,
  ReloadOutlined,
} from "@ant-design/icons";

function Home() {
  const automations = useAutomations();
  const app = useApp();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCsvModal, setShowCsvModal] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const onCancel = () => setShowAddModal(false);
  const onCancelEdit = () => {
    setShowEditModal(false);
    automations.actions.setEditAutomation({});
  };
  const onCancelCsv = () => {
    setShowCsvModal(false);
    automations.actions.setCsvAutomation({});
  };

  const onTableChange = (pagination: any, tablefilters: any, sorter: any) => {
    if (searchText) return;
    const { current, pageSize } = pagination;
    const query: any = {
      query: {
        limit: pageSize,
        page: current,
        ...(sorter &&
          Object.keys(sorter) && {
            sort: sorter?.order === "ascend" ? true : false,
          }),
      },
    };

    automations.actions.getAutomations(query);
  };

  useEffect(() => setFilteredData(automations.data), [automations.data]);

  if (!automations.automationReady)
    return (
      <div className="automations-container">
        <Spin size="large" />
      </div>
    );

  return (
    <div className="automations-container">
      {showAddModal && (
        <AddAutomation
          visible={showAddModal}
          onCancel={onCancel}
          automations={automations}
          setVisible={setShowAddModal}
          app={app}
        />
      )}

      {showEditModal && (
        <EditAutomation
          visible={showEditModal}
          onCancel={onCancelEdit}
          automations={automations}
          setVisible={setShowEditModal}
          app={app}
        />
      )}

      {showCsvModal && (
        <DownloadCsv
          visible={showCsvModal}
          onCancel={onCancelCsv}
          automations={automations}
          setVisible={setShowCsvModal}
          app={app}
        />
      )}

      <div className="add-automation-container">
        <Button
          className="button-primary"
          type="primary"
          onClick={() => {
            setShowAddModal(true);
          }}
          icon={<PlusOutlined />}
        >
          Add Automation
        </Button>
      </div>

      <Space direction="horizontal" size={"large"}>
        <Input
          placeholder="Search by name"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <Button
          className="button-primary"
          type="primary"
          onClick={() => {
            automations.actions.getAutomations({
              query: {
                searchQuery: searchText,
              },
            });
          }}
          icon={<SearchOutlined />}
        />
        <Button
          className="button-primary"
          type="primary"
          onClick={() => {
            setSearchText("");
            automations.actions.getAutomations({ query: {} });
          }}
          icon={<ReloadOutlined />}
        />
      </Space>
      <Table
        title={() => <h2>List Of Automations</h2>}
        columns={columns(automations, setShowEditModal, setShowCsvModal)}
        dataSource={filteredData}
        loading={automations.isLoading}
        pagination={{ ...automations.pagination, showSizeChanger: false }}
        rowKey={(record) => record.pk}
        onChange={onTableChange}
      />
    </div>
  );
}

export default Home;
