import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const LoadingPage: React.FC = () => {
  return (
    <div
      data-testid="loading-component"
      className="automations-container"
      style={{ height: "100vh" }}
    >
      <Spin
        size="large"
        // tip="Loading..."
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      />
    </div>
  );
};

export default LoadingPage;
