import { useStore } from "../store";
import { authBaseUrl, clientId, redirectUri } from "../config";
import { compareAsc } from "date-fns";

const { REACT_APP_BACKEND_ENDPOINT: BACKEND_ENDPOINT } = process.env;

interface ApiCallProps {
  method?: string;
  endpoint: string;
  params?: any;
  baseUrl?: string;
  token?: string;
}

export function useApi() {
  const {
    auth: { user = {} },
    actions: { setUser, removeUser },
  }: any = useStore();

  /**
   *
   * @param baseUrl
   * @param method
   * @param endpoint
   * @param params
   * @returns Promise
   */
  const getRequest = async ({
    method = "GET",
    endpoint,
    params,
    baseUrl,
    token,
  }: ApiCallProps) => {
    if (!token) token = await validateToken();

    let url = (baseUrl || BACKEND_ENDPOINT) + endpoint;

    const options: any = {
      headers: {
        Authorization: token ? `Bearer ${token}` : null,
      },
      method,
    };

    if (params)
      if (["POST", "PUT"].includes(method)) {
        options.headers["Content-Type"] = "application/json";
        options.body = JSON.stringify(params);
      } else {
        const queryString = Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&");
        url += "?" + queryString;
      }

    return fetch(url, options)
      .then(async (res) => ({
        status: res.status,
        data: await res.json(),
      }))
      .then(({ status, data }) => {
        if (status !== 200) {
          console.log({ status, data });
          const error: any = new Error(`${data?.message}`);
          error.code = data?.code;
          error.status = status;
          error.name = data?.error;
          throw error;
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };

  const validateToken = async () => {
    const expiresInDate = user?.expiresInDate;
    let newToken = user?.accessToken;
    // Check if token expired
    if (compareAsc(new Date(), new Date(expiresInDate)) === 1) {
      // refresh token
      try {
        const { access_token: accessToken, expires_in: expiresIn } =
          await getAuthRequest({ endpoint: "oauth2/token" });

        setUser({
          ...user,
          accessToken,
          expiresIn,
          expiresInDate: new Date(new Date().getTime() + expiresIn * 1000),
          isRefreshing: false,
        });

        newToken = accessToken;
      } catch (err) {
        console.log(err);
        // Logout user refresh token expired
        removeUser();
      }
    }

    return newToken;
  };

  const getAuthRequest = ({ endpoint, code }: any) => {
    return fetch(`${authBaseUrl}${endpoint}`, {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded",
      },
      body: getBody(code),
    })
      .then((res: any) => {
        if (!res.ok) {
          throw new Error(res.status);
        }
        return res;
      })
      .then((res) => res.json());
  };

  const getBody = (code: string) => {
    let details: any = {
      grant_type: code ? "authorization_code" : "refresh_token",
      client_id: clientId,
      ...(code
        ? {
            redirect_uri: redirectUri,
            code,
          }
        : { refresh_token: user.refreshToken }),
    };

    let body: string[] = [];

    for (let property in details) {
      let encodedKey: string = encodeURIComponent(property);
      let encodedValue: string = encodeURIComponent(details[property]);
      body.push(encodedKey + "=" + encodedValue);
    }

    return body.join("&");
  };

  return { getRequest, getAuthRequest };
}

function getCookie(cName: string) {
  const name = cName + "=";
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded.split("; ");
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
}

// window.getCookie = getCookie;
