import React, { useState } from "react";
import {
  Navigate,
  useOutlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Header from "./Header";
import { Layout, Menu } from "antd";
import sidebar from "../config/sidebar";
const { Content, Footer, Sider } = Layout;

const HomeLayout = ({ user, logOut }) => {
  const outlet = useOutlet();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const navigateTo = useNavigate();

  if (!user?.accessToken)
    return <Navigate to="/login" state={{ from: location }} replace />;

  return (
    <Layout>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "sticky",
          top: 0,
          left: 0,
        }}
        width={300}
      >
        <div className="logo" />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[location.pathname]}
          items={sidebar
            .filter(function filterRecursive(item) {
              return item.children
                ? (item.children = item.children.filter(filterRecursive)).length
                : !item.allow ||
                    user?.userGroups?.some((group) =>
                      item.allow.includes(group)
                    );
            })
            .map(function mapRecursive({
              name,
              key,
              label,
              path,
              icon,
              children,
            }) {
              return {
                key: name,
                path,
                label,
                icon,
                ...(path && { onClick: () => navigateTo(path) }),
                ...(children && { children: children.map(mapRecursive) }),
              };
            })}
        />
      </Sider>
      <Layout>
        <Header user={user} logOut={logOut} />

        <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
          <div style={{ padding: 24, background: "#fff", textAlign: "center" }}>
            {outlet}
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          RING ©2022 Created by Appdev
        </Footer>
      </Layout>
    </Layout>
  );
};

export default HomeLayout;
