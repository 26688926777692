import React, {useEffect, useRef} from "react";
import { CSVLink } from "react-csv";

type Props = {
  visible: boolean;
  automations: any;
  onCancel: any;
  setVisible: Function;
  app: any;
};

const DownloadCSV: React.FC<Props> = ({
  visible,
  automations,
  onCancel,
  setVisible,
  app,
}) => {

  const btnRef = useRef<HTMLElement>(null)
  useEffect(() => btnRef.current?.click(), [btnRef])

  return (
    <>
      {automations.csvAutomation.csv ?
        <CSVLink 
          data={automations.csvAutomation.csv}
          filename={automations.csvAutomation.filename}
        >
          <span ref={btnRef} />
        </CSVLink>
        :
        <></>
      }
    </>
  )
};

export default DownloadCSV;
