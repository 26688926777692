import React from "react";
import { Navigate, useOutlet, useLocation } from "react-router-dom";
import sidebar from "../config/sidebar";

interface User {
  accessToken: string;
  userGroups: Array<string>;
}

interface Props {
  user: User;
}

const AuthLayout = ({ user }: Props) => {
  const outlet = useOutlet();
  const location = useLocation();

  if (user?.accessToken) {
    const route: any = sidebar.find(
      (item) =>
        !item.allow ||
        user?.userGroups?.some(
          (group) => item.allow && item.allow.includes(group)
        )
    );

    const path =
      route?.path ||
      route.children.find(
        (item: any) =>
          !item.allow ||
          user?.userGroups?.some((group) => item.allow.includes(group))
      )?.path ||
      "/";

    console.log({
      path,
    });

    return <Navigate to={path} state={{ from: location }} replace />;
  }

  return outlet;
};

export default AuthLayout;
