import { useEffect } from "react";
import { message } from "antd";
import { useStore } from "../store";
import { authBaseUrl } from "../config";
import { useApi } from "../api";
function useAuth() {
  const { getRequest, getAuthRequest } = useApi();

  const {
    auth: { user, userLoaded },
    actions: { getUser, setUser, removeUser },
  } = useStore();

  useEffect(() => {
    if (!userLoaded) getUser();

    return () => {};
  }, [userLoaded, getUser]);

  const getAccessToken = (code) => {
    getAuthRequest({ endpoint: "oauth2/token", code }).then(
      ({
        access_token: accessToken,
        expires_in: expiresIn,
        refresh_token: refreshToken,
      }) => {
        getUserInfo({
          accessToken,
          refreshToken,
          expiresIn,
          expiresInDate: new Date(new Date().getTime() + expiresIn * 1000),
        });
      }
    );
  };

  const getUserInfoOld = () => {
    getRequest({
      baseUrl: `${authBaseUrl}`,
      endpoint: "oauth2/userInfo",
    }).then((userInfo) => {
      setUser({ ...user, ...userInfo });
    });
  };

  const getUserInfo = (userAuth = {}) => {
    getRequest({
      endpoint: "auth/userinfo",
      ...(userAuth?.accessToken && { token: userAuth.accessToken }),
    })
      .then(({ userInfo }) => {
        setUser({ ...user, ...userInfo, ...userAuth });
      })
      .catch((err) => {
        message.error("Failed to fetch user info", 0);
      });
  };

  const logOut = () => {
    removeUser();
  };

  return {
    user,
    userLoaded,
    actions: {
      getAccessToken,
      logOut,
      getUserInfo,
      getUserInfoOld,
    },
  };
}

export default useAuth;
