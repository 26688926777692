import React, { useState, useMemo, useEffect } from "react";
import Papa from "papaparse";
import {
  Form,
  Input,
  Space,
  Button,
  message,
  Upload,
  Select,
  DatePicker,
  Alert,
} from "antd";
import type { UploadFile } from "antd/es/upload/interface";
import { PageHeader } from "@ant-design/pro-layout";
import UploadCsv from "../../../../components/UploadCsv";
import useLdl from "../../../../hooks/useLdl";
import useAuth from "../../../../hooks/useAuth";
import useApp from "../../../../hooks/useApp";
import dayjs from "dayjs";
import CopyToClipBoard from "../../../../components/CopyToClipBoard";

const { Option } = Select;
type Props = {};

const LoadLeasedEmails: React.FC<Props> = () => {
  const ldl = useLdl();
  const { user }: any = useAuth();
  const app: any = useApp();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [defaultFileList, setDefaultFileList] = useState<UploadFile<any>[]>([]);
  const [uploadDisabled, setUploadDisabled] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorList, setErrorList] = useState("");

  const disabled = useMemo(
    () =>
      !ldl.loadLeasedEmailsSchema.isValidSync(ldl.loadLeasedEmails) ||
      !ldl.loadLeasedEmails.file,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ldl.loadLeasedEmails]
  );

  useEffect(() => {
    if (errorList) message.error(errorList);
  }, [errorList]);

  const validateColumns = (results: any): boolean =>
    results.data.some((line: any, index: number) => {
      const notANumber = line[0] && !Number(line[0]);
      // if value is not a number
      if (notANumber)
        setErrorList(`Line ${index + 1}: [${line[0]}] is not a valid number`);
      return notANumber;
    });

  const beforeUpload = (file: any) => {
    setUploadDisabled(true);
    setErrorList("");
    if (file.type !== "text/csv") {
      message.error(`${file.name} is not a csv file`);
      setUploadDisabled(false);
      return Upload.LIST_IGNORE;
    }

    Papa.parse(file, {
      skipEmptyLines: true,
      delimiter: ",",
      complete: function (results) {
        console.log({ results });
        // validates file is not empty
        if (!results.data.length) {
          message.error("File must not be empty");
          setDefaultFileList([]);
        }

        // validates file only has 2 columns
        if (results.data.some((line: any) => line.length === 0)) {
          message.error("File should have at least one column");
          setDefaultFileList([]);
        }

        if (validateColumns(results)) {
          setUploadDisabled(false);
          setDefaultFileList([]);
          return;
        }

        ldl.actions.setLoadLeasedEmails({
          ...ldl.loadLeasedEmails,
          filename: file.name.split(".csv")[0].split(" ").join("_"),
          file: file,
          csv: Papa.unparse(results.data, { newline: "\n" }).replace(/"/g, ""),
        });

        setUploadDisabled(false);
      },
    });

    return false;
  };

  const onChange = (info: any) => {
    setDefaultFileList(info.fileList);
    if (!info.fileList.length) {
      ldl.actions.setLoadLeasedEmails({
        ...ldl.loadLeasedEmails,
        filename: "",
        file: null,
        csv: "",
      });
    }

    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  return (
    <>
      <div className="site-page-header-ghost-wrapper">
        <PageHeader ghost={false} title="LDL" subTitle="Load Leased Emails" />
        {successMessage && (
          <Space
            size={"large"}
            direction="horizontal"
            style={{ marginBottom: 20 }}
          >
            <Alert
              message={`The job ${successMessage} has been created.`}
              type="info"
            />
            <CopyToClipBoard text={successMessage} />
          </Space>
        )}
      </div>
      <Space direction="vertical" size={"large"}>
        <Form name="basic" layout="vertical" form={form}>
          <Space direction="horizontal" size={"large"}>
            <Form.Item
              label="User Name"
              name="username"
              style={{ width: 250 }}
              initialValue={user?.usernameOG}
            >
              <Input disabled readOnly />
            </Form.Item>

            <Form.Item
              label="Request Date"
              name="requestDate"
              style={{ width: 250 }}
              initialValue={new Date().toISOString()}
            >
              <Input disabled readOnly />
            </Form.Item>
          </Space>

          <Form.Item label="File To Load">
            <UploadCsv
              beforeUpload={beforeUpload}
              onChange={onChange}
              defaultFileList={defaultFileList}
              setDefaultFileList={setDefaultFileList}
              disabled={uploadDisabled}
              setDisabled={setUploadDisabled}
            />
          </Form.Item>

          <Space direction="horizontal" size={"large"}>
            <Form.Item label="National ID Type" style={{ width: 250 }}>
              <Select
                showSearch
                placeholder="Select National ID type"
                onChange={(value) =>
                  ldl.actions.setLoadLeasedEmails({
                    ...ldl.loadLeasedEmails,
                    nationalIDType: value,
                  })
                }
                value={ldl.loadLeasedEmails.nationalIDType}
              >
                {app.config?.NATIONAL_ID_TYPES?.map(
                  (type: string, i: number) => (
                    <Option key={i} value={type}>
                      {type}
                    </Option>
                  )
                )}
              </Select>
            </Form.Item>

            <Form.Item label="From Vendor" style={{ width: 250 }}>
              <Select
                showSearch
                placeholder="Select Vendor"
                onChange={(value) =>
                  ldl.actions.setLoadLeasedEmails({
                    ...ldl.loadLeasedEmails,
                    vendorPartyID: value,
                  })
                }
                value={ldl.loadLeasedEmails.vendorPartyID}
              >
                {app.config?.VENDORS?.map((vendor: any, i: number) => (
                  <Option key={i} value={vendor.partyid}>
                    {vendor.vendor}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Space>

          <Form.Item label="Expiration Date" style={{ width: 250 }}>
            <DatePicker
              style={{ width: "100%" }}
              format="MM-DD-YYYY"
              disabledDate={(current) => {
                return dayjs() >= current;
              }}
              defaultValue={dayjs().add(1, "year")}
              onChange={(date, dateString) => {
                ldl.actions.setLoadLeasedEmails({
                  ...ldl.loadLeasedEmails,
                  expireDate: dateString,
                });
              }}
            />
          </Form.Item>

          <Form.Item>
            <Button
              data-testid="ale-submit"
              className="button-primary"
              type="primary"
              onClick={() =>
                ldl.actions.uploadLoadLeasedEmails({
                  setIsLoading,
                  form,
                  setDefaultFileList,
                  setDisabled: setUploadDisabled,
                  setSuccessMessage,
                })
              }
              disabled={disabled}
              loading={isLoading}
              style={{ width: 250, marginTop: 30 }}
            >
              EXECUTE
            </Button>
          </Form.Item>
        </Form>
      </Space>
    </>
  );
};

export default LoadLeasedEmails;
