import React from "react";
import { Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import type {
  UploadProps,
  UploadListProgressProps,
  UploadFile,
} from "antd/es/upload/interface";

interface UploadCsvProps extends UploadProps {
  defaultFileList?: UploadFile[];
  setDefaultFileList?: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  disabled?: boolean;
  setDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  maxCount?: number;
  listType?: UploadProps["listType"];
  multiple?: boolean;
  name?: string;
  itemRender?: UploadProps["itemRender"];
  beforeUpload?: UploadProps["beforeUpload"];
  onChange?: UploadProps["onChange"];
}

export default function CustomUploadCsv({
  defaultFileList = [],
  setDefaultFileList,
  disabled = false,
  setDisabled,
  maxCount = 1,
  listType = "picture",
  multiple = false,
  name = "file_name",
  itemRender = (originNode) => {
    return <div style={{ width: 525 }}>{originNode}</div>;
  },
  beforeUpload,
  onChange,
  ...props
}: UploadCsvProps) {
  const progress: UploadListProgressProps = {
    strokeColor: {
      "0%": "#108ee9",
      "100%": "#87d068",
    },
    strokeWidth: 3,
    format: (percent?: number): React.ReactNode =>
      `${percent !== undefined ? parseFloat(percent.toFixed(2)) : 0}%`,
  };

  const uploadProps = {
    name,
    multiple,
    disabled,
    defaultFileList,
    listType,
    accept: ".csv",
    maxCount,
    progress,
    itemRender,
    beforeUpload,
    onChange,
    ...props,
  };

  return (
    <div style={{ display: "flex" }}>
      <Upload
        {...uploadProps}
        fileList={defaultFileList}
        data-testid="upload-input"
      >
        {defaultFileList.length >= 1 ? null : (
          <Button style={{ width: 250 }} icon={<UploadOutlined />}>
            Upload File
          </Button>
        )}
      </Upload>
    </div>
  );
}
