export const authBaseUrl = process.env.REACT_APP_AUTH_BASE_URL;

export const clientId = process.env.REACT_APP_CLIENT_ID;

export const redirectUri = process.env.REACT_APP_REDIRECT_URI;

export const uploadTypes = [
  {
    label: "Emails Only",
    value: "email_only",
  },
  {
    label: "Full Profile",
    value: "full_profile",
  },
  {
    label: "HCPID",
    value: "hcpid",
  },
  {
    label: "Target List",
    value: "scrub",
  },
  {
    label: "Suppression List",
    value: "suppression",
  },
];
